var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "teacher-submissions", attrs: { color: "#ffffff" } },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                { staticClass: "py-2", attrs: { "center-active": "" } },
                [
                  _vm.$currentUser.isDistrictAdmin &&
                  !_vm.selectedWeek &&
                  !_vm.selectedTeacher
                    ? _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.currentSchoolName)
                                              )
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1339536818
                              )
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.schoolItems, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.value,
                                      class: {
                                        "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                          _vm.currentSchoolId &&
                                          item.value == _vm.currentSchoolId
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.currentSchoolId = item.value
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.text) + " ")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.selectedWeek && !_vm.selectedTeacher
                    ? _c(
                        "v-slide-item",
                        { key: 1 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportYear
                                                    ? _vm.reportYear.yearName
                                                    : ""
                                                )
                                              )
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2726627563
                              )
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.adminSchoolYears, function(year) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: year.yearId,
                                      class: {
                                        "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                          _vm.reportYear &&
                                          year.yearId == _vm.reportYear.yearId
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.reportYear = year
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(year.yearName) + " ")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedWeek && _vm.getWeekIndex > 0
                    ? _c(
                        "v-slide-item",
                        { key: 2 },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                outlined: "",
                                rounded: "",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getNextWeek(-1)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("fal fa-chevron-left")]
                              ),
                              _c("span", [_vm._v(_vm._s(_vm.$t("priorLabel")))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedWeek
                    ? _c(
                        "v-slide-item",
                        { key: 3 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportWeek
                                                    ? _vm.reportWeek.text
                                                    : ""
                                                )
                                              )
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3829313374
                              )
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.weeksList, function(week) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: week.startDate,
                                      class: {
                                        "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                          week.startDate ==
                                          _vm.reportWeek.startDate
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.reportWeek = week
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(week.text) + " ")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedWeek &&
                  _vm.getWeekIndex + 1 < _vm.weeksList.length
                    ? _c(
                        "v-slide-item",
                        { key: 4 },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                rounded: "",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getNextWeek(1)
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(_vm.$t("nextLabel")))]),
                              _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { small: "" } },
                                [_vm._v("fal fa-chevron-right")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.selectedTeacher && !_vm.selectedWeek
        ? _c(
            "v-card",
            { staticClass: "rounded-0", attrs: { flat: "" } },
            [
              _c("v-data-table", {
                ref: "table",
                attrs: {
                  headers: _vm.filteredHeaders,
                  items: _vm.teacherStats,
                  height: _vm.tableHeight,
                  "fixed-header": "",
                  "item-key": "yearId",
                  "disable-pagination": "",
                  "items-per-page": -1,
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.displayName",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            {
                              staticClass: "clickable",
                              attrs: { tabindex: "0", role: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.showTeacher(item)
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.showTeacher(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "mr-2 white--text",
                                  staticStyle: { "font-weight": "lighter" },
                                  attrs: { color: "primary", size: "2rem" }
                                },
                                [
                                  _vm.hasText(_vm.getTeacherItem(item).photoUrl)
                                    ? _c("v-img", {
                                        attrs: {
                                          src: _vm.getTeacherItem(item).photoUrl
                                        }
                                      })
                                    : _c("span", { staticClass: "body-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getInitials(
                                              _vm.getTeacherItem(item)
                                            )
                                          )
                                        )
                                      ])
                                ],
                                1
                              ),
                              _vm._v(" " + _vm._s(item.displayName) + " ")
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.yearName",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("td", [_vm._v(_vm._s(item.yearName))])]
                      }
                    },
                    {
                      key: "item.currentWeekSubmission",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("td", [
                            item.currentWeekSubmission
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { color: "green" },
                                    attrs: { align: "center" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("completeLabel")))]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { align: "center" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("unsubmittedlabel")))]
                                )
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.onTime",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("td", [_vm._v(_vm._s(item.onTime))])]
                      }
                    },
                    {
                      key: "item.late",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("td", [_vm._v(_vm._s(item.late))])]
                      }
                    },
                    {
                      key: "item.unsubmited",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("td", [_vm._v(_vm._s(item.unsubmited))])]
                      }
                    },
                    {
                      key: "item.upcoming",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("td", [_vm._v(_vm._s(item.upcoming))])]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedTeacher,
              expression: "selectedTeacher"
            }
          ],
          attrs: { flat: "" }
        },
        [
          _vm.$vuetify.breakpoint.smAndDown && _vm.selectedTeacher
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2 mb-2 d-flex flex-nowrap" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-0 pt-0 ml-0 pl-0",
                          attrs: { small: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.selectedTeacher = null
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-xmark")])],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-avatar",
                        {
                          staticClass: "mr-2 white--text",
                          staticStyle: { "font-weight": "lighter" },
                          attrs: { color: "primary", size: "2rem" }
                        },
                        [
                          _vm.hasText(
                            _vm.getTeacherItem(_vm.selectedTeacher).photoUrl
                          )
                            ? _c("v-img", {
                                attrs: {
                                  src: _vm.getTeacherItem(_vm.selectedTeacher)
                                    .photoUrl
                                }
                              })
                            : _c("span", { staticClass: "body-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getInitials(
                                      _vm.getTeacherItem(_vm.selectedTeacher)
                                    )
                                  )
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "ml-2 mt-2 font-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(_vm.selectedTeacher.displayName) +
                              ":  " +
                              _vm._s(_vm.selectedTeacher.yearName)
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-data-table", {
            staticClass: "table",
            attrs: {
              headers: _vm.teacherHeaders,
              "fixed-header": "",
              "items-per-page": -1,
              "disable-pagination": "",
              "hide-default-footer": "",
              items: _vm.teacherWeeks,
              "item-key": "startDate",
              height: _vm.tableHeight
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.startDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "td",
                        {
                          style: {
                            backgroundColor: item.currentWeek
                              ? _vm.currentDateBackgroundColor
                              : "",
                            color: item.currentWeek
                              ? _vm.currentDateTextColor
                              : ""
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.displayFormat(item.startDate, true)) +
                                " - " +
                                _vm._s(_vm.weekEnd(item.startDate))
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.submitDate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("td", [
                        item.submitDate
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm.timezoneDate(item.submitDate))
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  }
                },
                {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("td", [
                        item.status === "Unsubmitted"
                          ? _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.status))
                            ])
                          : item.status === "Late"
                          ? _c("div", { staticStyle: { color: "orange" } }, [
                              _vm._v(_vm._s(item.status))
                            ])
                          : _c("div", { staticStyle: { color: "green" } }, [
                              _vm._v(_vm._s(item.status))
                            ])
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.selectedWeek
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-data-table", {
                staticClass: "table",
                attrs: {
                  headers: _vm.weekHeaders,
                  "fixed-header": "",
                  "items-per-page": -1,
                  items: _vm.weekItems,
                  "disable-pagination": "",
                  "hide-default-footer": "",
                  "item-key": "yearId",
                  height: _vm.tableHeight
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.displayName",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "mr-2 white--text",
                                  staticStyle: { "font-weight": "lighter" },
                                  attrs: { color: "primary", size: "2rem" }
                                },
                                [
                                  _vm.hasText(_vm.getTeacherItem(item).photoUrl)
                                    ? _c("v-img", {
                                        attrs: {
                                          src: _vm.getTeacherItem(item).photoUrl
                                        }
                                      })
                                    : _c("span", { staticClass: "body-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getInitials(
                                              _vm.getTeacherItem(item)
                                            )
                                          )
                                        )
                                      ])
                                ],
                                1
                              ),
                              _vm._v(" " + _vm._s(item.displayName) + " ")
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("td", [
                            item.status === "Unsubmitted"
                              ? _c("div", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(item.status))
                                ])
                              : item.status === "Late"
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "orange" } },
                                  [_vm._v(_vm._s(item.status))]
                                )
                              : _c("div", { staticStyle: { color: "green" } }, [
                                  _vm._v(_vm._s(item.status))
                                ])
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }