



































































































































































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DateTimeUtils from '@/utils/date-time-utils';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import { tableWidths } from '@/constants/index';
import moment from 'moment';

const settings = namespace('settings');
const schoolstatus = namespace('schoolstatus')
const teachers = namespace('teachers');

@Component({
  mixins: [PageLifeCycleMixin, TableResizeMixin]
})
export default class TeachersSubmissions extends Vue {
tableHeight!: string;

@settings.Getter('getDateStyling')
dateStyling!: any;

@settings.Getter('getSchools')
schools!: any;

@teachers.Getter('getTeachersOnly')
adminItems!: any[];

@schoolstatus.Action
getSchoolSubmittedStatus!: (param?: any) => Promise<any>;

@schoolstatus.Action
getTeacherReport!: (param?: any) => Promise<any>;

@settings.Action
loadAdminSchoolYears!: () => Promise<any>;

@settings.State
adminSchoolYears!: Array<any>;

yearPassed = true;
selectedTeacher: any = null;
selectedWeek: any = null;
reportYear: any = null;
reportWeek: any = null;
nextDueDate: any;
weeksList: Array<any> = [];
allWeeks: any = [];
teacherStats: any = [];
teacherWeeks: any = [];
weekItems: any = [];

headers = [
  { text: this.$t('teacherLabel'), value: 'displayName', width: tableWidths.longText, type: 'A' },
  { text: this.$t('yearLabel'), value: 'yearName', width: tableWidths.longText, type: 'A' },
  { text: this.$t('upcomingWeekLabel'), value: 'currentWeekSubmission', align: 'center', type: 'C' },
  { text: this.$t('onTimeLabel'), value: 'onTime', width: '140px', type: 'A' },
  { text: this.$t('lateLabel'), value: 'late', width: '140px', type: 'A' },
  { text: this.$t('unsubmittedlabel'), value: 'unsubmited', width: '140px', type: 'A' },
  { text: this.$t('upcomingLabel'), value: 'upcoming', width: '140px', type: 'C' }
];

teacherHeaders = [
  { text: this.$t('weekLabel'), value: 'startDate', width: 150 },
  { text: this.$t('submittedLabel'), value: 'submitDate', width: 150 },
  { text: this.$t('statusLabel'), value: 'status', width: 150 }
];

weekHeaders = [
  { text: this.$t('teacherLabel'), value: 'displayName', width: 150 },
  { text: this.$t('yearLabel'), value: 'yearName', width: 150 },
  { text: this.$t('statusLabel'), value: 'status', width: 150 }
];

loadYears() {
  if (this.adminSchoolYears.length === 0) {
    return this.loadAdminSchoolYears()
  } else {
    return Promise.resolve();
  }
}

get hasText() {
  return CommonUtils.hasText;
}

get schoolItems() {
  return this.schools.map((s: any) => {
    return { text: s.schoolName, value: +s.schoolId };
  });
}

get currentSchoolName() {
  return this.schoolItems.find((s: any) => s.value === this.currentSchoolId).text;
}

get currentSchoolId() {
  return this.$store.getters['settings/getSchoolId'];
}

set currentSchoolId(val: any) {
  console.log(this.currentSchoolId, this.schoolItems)
  this.$store.commit('settings/setSchoolYearCurrentSchoolId', val);
  CommonUtils.showLoading();
  this.loadAdminSchoolYears().then(() => {
    if (this.reportYear && !this.adminSchoolYears.find((y) => y.yearId === this.reportYear.yearId)) {
      this.reportYear = null;
      CommonUtils.hideLoading();
    } else {
      this.yearChange();
    }
  })
}

get hasData() {
  return this.teacherStats.length > 0;
}

getTeacherItem(teacher: any) {
  return this.adminItems.find((i: any) => i.teacherId === teacher.teacherId) || {}
}

getInitials(teacher: any) {
  try {
    let initials = '';
    if (CommonUtils.hasText(teacher.firstName)) {
      initials += teacher.firstName[0];
    }

    if (CommonUtils.hasText(teacher.lastName)) {
      initials += teacher.lastName[0];
    }

    if (
      CommonUtils.hasNoText(initials) &&
      CommonUtils.hasText(teacher.displayName)
    ) {
      initials += teacher.displayName[0];
    }

    if (CommonUtils.hasNoText(initials)) {
      initials += teacher.emailAddress[0];
    }
    return initials.toUpperCase();
  } catch (e) {
    console.log(e);
  }
}

get displayFormat() {
  return DateTimeUtils.formatToDisplay;
}

get weekEnd() {
  return (d: any) => DateTimeUtils.formatToDisplay(DateTimeUtils.getEndOfWeek(d), true)
}

get currentDateBackgroundColor() {
  return CommonUtils.getColor(this.dateStyling.currentDateBackgroundColor || 'inherit');
}

get currentDateTextColor() {
  return CommonUtils.getColor(this.dateStyling.currentDateTextColor || 'inherit');
}

get getWeekIndex() {
  return this.weeksList.indexOf(this.reportWeek);
}

get filteredHeaders() {
  if (this.yearPassed) {
    return this.headers.filter((h) => h.type === 'A')
  } else {
    return this.headers;
  }
}

get formatTimeStampToDisplay() {
  return DateTimeUtils.formatTimestampToDisplay;
}

timezoneDate(date:any) {
  return this.formatTimeStampToDisplay(moment.utc(date).local().format('MM/DD/YYYY HH:mm'), false);
}

created() {
  CommonUtils.showLoading();
  this.loadYears().then(() => {
    this.reportYear = this.adminSchoolYears[0];
  })
}

showTeacher(yearStats: any) {
  this.selectedTeacher = yearStats;
  CommonUtils.showLoading();
  this.getTeacherReport({
    yearId: this.selectedTeacher.yearId
  }).then((d) => {
    this.teacherWeeks = d.data.allWeeks.sort((a: any, b: any) => {
      if (DateTimeUtils.isThisDateBeforeThatDate(a.startDate, b.startDate)) {
        return -1;
      } else {
        return 1;
      }
    })
    let foundCurrentWeek = false;
    this.teacherWeeks = this.teacherWeeks.map((w: any) => {
      w.currentWeek = (!foundCurrentWeek && DateTimeUtils.isThisDateBeforeThatDate(DateTimeUtils.currentDateAddDays(-7), w.startDate));
      if (w.currentWeek) {
        foundCurrentWeek = true;
      }
      return w;
    })
    CommonUtils.hideLoading();
  })
}

showWeek() {
  this.selectedWeek = true;
  if (this.yearPassed) {
    this.reportWeek = this.weeksList[this.weeksList.length - 1];
  } else {
    this.reportWeek = this.weeksList.find((w) => DateTimeUtils.isThisDateBeforeThatDate(DateTimeUtils.currentDateAddDays(0), w.startDate));
  }
}

@Watch('reportWeek')
changeWeek() {
  this.weekItems = [];
  for (const i in this.teacherStats) {
    const currentTeacher = this.teacherStats[i];
    const toAppend = { displayName: currentTeacher.displayName, teacherId: currentTeacher.teacherId, yearName: currentTeacher.yearName, yearId: currentTeacher.yearId, status: 'Unsubmitted' }
    const teachersSubmission = this.reportWeek.submissions.find((s: any) => s.yearId === currentTeacher.yearId)
    if (teachersSubmission) {
      toAppend.status = teachersSubmission.status;
    }
    this.weekItems.push(toAppend);
  }
}

getNextWeek(change: any) {
  const i = this.getWeekIndex;
  this.reportWeek = this.weeksList[i + change];
}

openMessageEditor(submitted: boolean) {
  let teachers;
  if (submitted && this.selectedWeek) {
    teachers = this.weekItems.filter((t: any) => { return t.status !== 'Unsubmitted' }).map((t: any) => t.teacherId);
  } else if (submitted) {
    teachers = this.teacherStats.filter((t: any) => { return t.currentWeekSubmission }).map((t: any) => t.teacherId)
  } else if (this.selectedWeek) {
    teachers = this.weekItems.filter((t: any) => { return t.status === 'Unsubmitted' }).map((t: any) => t.teacherId);
  } else {
    teachers = this.teacherStats.filter((t: any) => { return !t.currentWeekSubmission }).map((t: any) => t.teacherId)
  }
  this.$eventBus.$emit('openSubPage', {
    type: 'message',
    modal: true,
    input: {
      action: 'submissions',
      teachers: teachers
    }
  });
}

@Watch('reportYear')
yearChange() {
  if (!this.reportYear) {
    this.teacherStats = [];
    CommonUtils.hideLoading();
    return;
  }
  CommonUtils.showLoading();
  this.getSchoolSubmittedStatus({
    yearId: this.reportYear.yearId
  }).then((d) => {
    this.teacherStats = d.data.teachers;
    this.yearPassed = (d.data.nextDueDate === undefined);
    this.nextDueDate = d.data.nextDueDate;
    if (this.yearPassed) {
      this.headers[2].text = this.$t('upcomingWeekLabel');
    } else {
      this.headers[2].text = this.$t('upcomingWeekDueLabel', { date: this.timezoneDate(this.nextDueDate) });
    }
    this.weeksList = d.data.weeks.sort((a: any, b: any) => {
      if (DateTimeUtils.isThisDateBeforeThatDate(a.startDate, b.startDate)) {
        return -1;
      } else {
        return 1;
      }
    });
    this.weeksList = this.weeksList.map((w) => {
      w.text = this.displayFormat(w.startDate, true) + ' - ' + this.weekEnd(w.startDate);
      return w;
    })
    CommonUtils.hideLoading()
  })
}
}

